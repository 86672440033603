'use client';
import Image from 'next/image';

export default function Error({ reset }) {
  return (
    <html lang="en">
      <body>
        <a href="/" className="relative z-10 mt-10 flex w-full justify-center">
          <Image
            src="/static/svg/transinfoLogoWhite.svg"
            alt="TransInfo"
            width={400}
            height={100}
          />
        </a>
        <h1 className="relative z-10 mt-48 text-center text-3xl  text-white md:text-5xl">
          Something went wrong!
        </h1>
        <button onClick={reset}>Try again</button>
        <Image
          src="/static/404.jpg"
          fill={true}
          style={{ objectFit: 'cover' }}
          className="z-0"
          alt="404"
        />
      </body>
    </html>
  );
}
